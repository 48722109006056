import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Ready.module.scss"

const Ready = ({ strapiData }) => {
  return (
    <div className={styles.relative}>
      <div className={styles.ready}>
        <Container className={styles.con}>
          <div className={styles.readyBg}>
            <div className={styles.row}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div className="">
                <Link to="/dedicated-development-team/">
                  <button
                    className={styles.readyBtn}
                    id="hp_hero_section_start_project_btn_gt"
                  >
                    Scale With Remote Developers
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Ready
