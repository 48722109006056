// extracted by mini-css-extract-plugin
export var Frame = "Tools-module--Frame--ad448";
export var Rec = "Tools-module--Rec--118ba";
export var advance = "Tools-module--advance--bdc26";
export var cir = "Tools-module--cir--75c6a";
export var dots = "Tools-module--dots--a22d6";
export var for1 = "Tools-module--for1--05163";
export var heading = "Tools-module--heading--b76c9";
export var iconssCard = "Tools-module--iconssCard--a5ab4";
export var techBg = "Tools-module--techBg--65757";
export var techDot = "Tools-module--techDot--2c82e";
export var techIcon = "Tools-module--techIcon--7d419";
export var techImg = "Tools-module--techImg--333fc";
export var technologyIcon = "Tools-module--technologyIcon--a0783";