// extracted by mini-css-extract-plugin
export var bgImg = "Elevate-module--bgImg--37917";
export var build = "Elevate-module--build--29f28";
export var buildInner = "Elevate-module--buildInner--d05d5";
export var card = "Elevate-module--card--8c0bd";
export var cardData = "Elevate-module--cardData--1765c";
export var description = "Elevate-module--description--9c12f";
export var heading = "Elevate-module--heading--1a583";
export var pad = "Elevate-module--pad--f45d8";
export var ready = "Elevate-module--ready--1865c";
export var step = "Elevate-module--step--6adc2";
export var step01 = "Elevate-module--step01--9cce8";