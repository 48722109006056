// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--918f6";
export var clientDots2 = "Testimonials-module--clientDots2--634a8";
export var content = "Testimonials-module--content--64ff5";
export var dots3 = "Testimonials-module--dots3--b3176";
export var hireArrow = "Testimonials-module--hireArrow--55ab0";
export var iconContainer = "Testimonials-module--iconContainer--1f0ee";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--04800";
export var imgBtn = "Testimonials-module--imgBtn--a0430";
export var person = "Testimonials-module--person--a43f9";
export var personBg = "Testimonials-module--personBg--3abeb";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--e4ce4";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--63085";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--13e2f";
export var row = "Testimonials-module--row--2cb99";
export var section = "Testimonials-module--section--0257f";
export var staffRec = "Testimonials-module--staffRec--dc4e3";
export var testName = "Testimonials-module--testName--43828";
export var testimonials = "Testimonials-module--testimonials--9ddec";
export var testimonialsContent = "Testimonials-module--testimonialsContent--95d43";
export var vector2 = "Testimonials-module--vector2--535d1";