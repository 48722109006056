// extracted by mini-css-extract-plugin
export var SliderWidth = "Technologies-module--SliderWidth--2f718";
export var arrowImg = "Technologies-module--arrowImg--cc189";
export var card = "Technologies-module--card--cf3a6";
export var cardBg = "Technologies-module--cardBg--9604d";
export var cardWrapper = "Technologies-module--cardWrapper--9b12c";
export var cards = "Technologies-module--cards--5673d";
export var description = "Technologies-module--description--a7e30";
export var heading = "Technologies-module--heading--a3f8f";
export var headingPremium = "Technologies-module--headingPremium--93b22";
export var premiumBg = "Technologies-module--premiumBg--e2a8c";
export var premiumImg = "Technologies-module--premiumImg--420f7";
export var providingImg = "Technologies-module--providingImg--14a7b";
export var teamButton = "Technologies-module--teamButton--4321e";
export var trailBg = "Technologies-module--trailBg--7ca88";