import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Elevate.module.scss"

const Elevate = ({ strapiData }) => {
  return (
    <div className={styles.build}>
      <div>
        <Container className={styles.pad}>
          <div className={styles.buildInner}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            ></h2>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className={styles.cardData}>
              {strapiData?.cards?.map((e, i) => (
                <div className={styles.step}>
                  <p className={styles.step01}>Step 0{i + 1}</p>
                  <div className={styles.card}>
                    <div className={styles.bgImg}>
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        decoding="async"
                        loading="lazy"
                        alt={e?.title}
                      />
                    </div>
                    <h3>{e?.title}</h3>
                    <p>{e?.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Elevate
