// extracted by mini-css-extract-plugin
export var btn = "WhyChoose-module--btn--35f15";
export var cards = "WhyChoose-module--cards--0c2e4";
export var description = "WhyChoose-module--description--151eb";
export var hire = "WhyChoose-module--hire--cb42b";
export var hireCyber = "WhyChoose-module--hireCyber--cc100";
export var hireImg = "WhyChoose-module--hireImg--35496";
export var talent = "WhyChoose-module--talent--1f2e9";
export var teamBtn = "WhyChoose-module--teamBtn--1acb1";
export var text = "WhyChoose-module--text--27a47";
export var tick = "WhyChoose-module--tick--e328c";